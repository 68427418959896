<template>
  <div v-if="!getIsMobile" class="input_container">
    <form :action="sendCommentForm" @submit.prevent class="input_field">
    <input v-model="comment" type="text" class="input_field" autocomplete="off" :placeholder="$t('player.leave_your_comment')" name="name" id='name' />
    <button @click="sendComment" class="input_container__button">
      <span style="color: #333; text-wrap: nowrap; font-size: 15px; margin-right: 10px;">{{ $t('player.comment') }}</span>
      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.33333 1L1 10.6H8.5L7.66667 17L16 7.4H8.5L9.33333 1Z" stroke="#00483B" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

    </button>
  </form>
  </div>
  <div v-else class="input_container_mobile">
   
    <input v-model="comment" type="text" class="input_field_mobile" autocomplete="off" :placeholder="$t('player.leave_your_comment')" name="name" id='name' />
    <button @click="sendComment" class="input_container__button_mobile">
      <svg class="icon_send_mobile" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.6066 8.6066H3.93934M15.6066 8.6066L1.28769 15.5009L3.93934 8.6066M15.6066 8.6066L1.28769 1.71231L3.93934 8.6066" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      comment: ""
    }
  },
  computed: {
    ...mapGetters(['getIsMobile'])
  },
  methods: {
    ...mapActions(['makeAComment']),
     sendCommentForm(e){
      e.preventDefault();
      this.sendComment();
    },
    sendComment() {
      if (this.comment.length) {
        this.makeAComment({ comment: this.comment });
      }
      setTimeout(() => {
        this.comment = "";
      }, 1000);
    }
  }
}
</script>

<style scoped lang="scss">
.input_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: no-wrap;
  border-radius: 50px;
  padding-right: 80px;
  background: var(--background2-v2);
}
.input_container_mobile {
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  border-radius: 50px;
  padding-right: 26px;
}
.input_field {
  padding-left: 20px;
  padding-right: 100px;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  width: 100%;
  background-color: var(--background2-v2);
  background: var(--background2-v2);
  color: var(--fontcolor-v2);
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 17px;
}

.input_field_mobile {
  padding-left: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 50px;
  width: 100%;
  background-color: var(--background2-v2) !important;
  color: var(--fontcolor-v2);
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  margin-right: 6px;
}
.input_field::placeholder {
  color: var(--fontcolor2-v2);
}
.input_field_mobile::placeholder {
  color: var(--fontcolor2-v2);
}
.input_container__button {
  background-color: var(--maincolor) !important;
  border: none;
  color: #fff;
  padding-left: 20px;
  width: 151px;
  right: 10px;
  position: absolute;
  padding-right: 26px;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}

.input_container__button_mobile {
  background-color: var(--background2-v2) !important;
  border: none;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}
.input_field_mobile {
  padding-left: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 50px;
  width: 100%;
  background-color: var(--background2-v2);
  background: var(--background2-v2);
  color: var(--fontcolor-v2);
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
}
.input_field_mobile::placeholder {
  color: var(--fontcolor2-v2);
}
.icon_send_mobile {
  stroke: var(--fontcolor-v2)
}
</style>