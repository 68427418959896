<template>
  <div v-if="showComments" class="comments_container">
    <span @click="openComments" class="comment_label">{{
      $t("player.comments")
    }}</span>
    <CommentInput v-if="!getIsMobile" />
    <div @scroll="handleScroll" class="scroll-infinity">
      <CommentList
      v-for="comment in getCurrentLessonComments"
      :key="comment.id"
      v-show="!getIsMobile"
      :comment="comment"
      />
      <transition name="fade">
        <div class="d-flex justify-content-center mt-5" v-if="getLessonCommentsLoading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </transition>
    </div>
    <b-modal
      id="modal-comments-V2"
      style="border-radius: 0px !important"
      hide-header
      hide-footer
      content-class="pa-0 ma-0 modal-content"
    >
      <slot name="modal-header-close">
        <div class="close" @click="hideModal">×</div>
      </slot>
      <slot name="modal-title">
        <p class="text1">Comentários</p>
      </slot>
      <slot name="default">
        <div @scroll="handleScroll" class="scroll-infinity" style="height: 600px!important;">
          <CommentList
            v-for="comment in getCurrentLessonComments"
            :key="comment.id"
            :comment="comment"
          />
            <transition name="fade">
            <div class="d-flex justify-content-center mt-5" v-if="getLessonCommentsLoading">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </transition>
        </div>
        <div
          v-if="!getCurrentLessonComments.length"
          style="min-height: 50vh"
        ></div>
        <CommentInput
          class="mt-3"
          style="position: relative; padding: 0px; margin: 0px"
        />
      </slot>
    </b-modal>
  </div>
</template>

<script>
import CommentInput from "./CommentInput/index.vue";
import CommentList from "./CommentList/index.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      commentLesson: "",
    };
  },
  methods: {
    ...mapActions(["loadLessonComments"]),
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-comments-V2", "#btnShow");
    },
    async handleScroll(event) {
      if (
        event.target.scrollTop + event.target.clientHeight >=
        event.target.scrollHeight -5
      ) {
        if(this.getLessonCommentsLoading) return;
        if (this.getLessonCommentsCurrentPage === this.getLessonCommentstotalPage) return;

        let page = this.getLessonCommentsCurrentPage;

        if (
          page < this.getLessonCommentstotalPage
        ) {
          page++
          const courseId = this.getCurrentCourse.id;
          const moduleId = this.getCurrentModule.id;
          const lessonId = this.getCurrentLesson.id;
          if (courseId, moduleId, lessonId, page) {
            const payload = {
              course: courseId,
              module: moduleId,
              lesson: lessonId,
              page: page,
            };
            this.loadLessonComments(payload);
          }
        } 
      }
    },
    openComments() {
      if (this.getIsMobile) {
        this.$root.$emit("bv::show::modal", "modal-comments-V2", "#btnShow");
      }
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentLessonComments",
      "getIsMobile",
      "getPlayerStatus",
      "getPlayerConfig",
      "getLessonCommentstotalPage",
      "getLessonCommentsCurrentPage",
      "getLessonCommentsLoading",
      "getCurrentCourse",
      "getCurrentModule",
      "getCurrentLesson",
    ]),
    showComments() {
      if (this.getPlayerConfig.disable_comments_courses === "started") {
        return false;
      }
      const filteredCourse =
        this.getPlayerConfig.disable_comments_courses.filter(
          (course) => course.id == this.getCurrentCourse.id
        );
      return filteredCourse.length === 0;
    },
  },
  components: {
    CommentInput,
    CommentList,
  },
};
</script>

<style scoped>
.comment_label {
  color: var(--fontcolor-v2);
  margin-top: 21px;
  margin-bottom: 16px;
  font-size: 14px;
}
.comments_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed var(--bordercolor-v2);
  border-width: 2px;
}
@media screen and (min-width: 768px) {
  .comments_container {
    margin-left: 0px;
    margin-top: 50px;
  }
  .comment_label {
    margin-left: 6px;
  }
}
.modal-dialog {
  margin: 0px !important;
  border-radius: 0px !important;
}
</style>

<style scoped lang="scss">
.close {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
  top: -10px;
  text-align: center;
  font-size: 30px;
  opacity: 1;
  text-shadow: inherit;
  font-weight: lighter;
  cursor: pointer;
  color: var(--fontcolor-v2);
  background: var(--background2-v2);
}
.modal-content {
  background-color: var(--background-v2) !important;
  border-radius: 0px !important;
  min-height: 100vh;
}

#modal-comments-V2 {
  padding: 0px !important;
  border-radius: 0px !important;
  z-index: 99999999999;
  .modal-header {
    border: none;
  }
  .allContentModal {
    padding: 0px;
    margin: 0 auto;
    display: block;
    // border-radius: 30px;
    text-align: -webkit-left;
  }
  .spaceText2 {
    margin-bottom: 20px;
  }
  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .text1 {
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--fontcolor-v2);
  }
  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.2px;
    font-size: 17px;
    color: var(--fontcolor-v2);
  }

  .button {
    width: 100%;
    height: 55px;
    border-radius: 30px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;

    border: none;
    margin-top: 30px;

    background: var(--maincolor);
    color: var(--fontcolor3-v2);
  }
}

.scroll-infinity {
  overflow-y: auto !important;
  height: 600px !important;
  -ms-hoverflow-style: none !important;
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .scroll-infinity {
    height: 0px !important;
  }
}
</style>